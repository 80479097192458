.form{
    text-align: center;
}
#Reason {
    height: 100px;
    width: 800px;
}
.leave{
    padding-top: 25px;
}
.leaveform{
    /* text-align: -webkit-center; */
    width: 200px;
    margin-left: 33%;
    margin-top: -67px;
}
.reason{
    height: 91px;
    width: 60%;
}
#Type{
    width: 23%;
}
.emailmultiple{
    width: 60%;
}
/* .icon{
    
    padding-right: 79px;
    float: right;
    justify-content: flex-end;
} */

.icon {
    position: absolute;
    right: 7rem;
    top: 64px;
    justify-content: flex-end;
    background: #fff;
    padding: 10px;
    box-shadow: 0px 2px 11px #a1a1a1;
    border-radius: 50%;
}
.iconbtn{
    margin-top: -87px;
}

.button1{
    position: relative;
    left: -1rem;
}


.button2{
    position: relative;
    left: -1rem;
}