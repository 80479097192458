.button{
    padding: 0px 13px 5px;
    background: red;
    border-color: red
}
.button:hover {
    background: #cf3a3a !important;
    border-color: #cf3a3a !important
}

.edit_btn{
    padding: 0px 13px 5px;

}
.primary-button {
  background: #ff8f27 !important;
  color: #fff !important;
}

.mainfrom {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  box-shadow: 0px 1px 11px 0px #80808085;
  padding: 21px 10px;
}
.plus_btn{
    display: flex;
    justify-content: flex-end;
}

.btn_form{
    background: #535356;
    border-color: #535356
}

.btn_form:hover{
    background: #808086 !important;
    border-color: #808086 !important
}

.ant-select-selection-selected-value {
    border-radius: 0px 8px 8px 0px;
    height: 53px;
  }
  
  .ant-select-selection.ant-select-selection--single {
    height: 53px;
  }
  
  .ant-select-selection.ant-select-selection--single
    > div
    > div
    > div
    > div
    + div {
    margin-top: -5px;
    padding: 4px 5px 5px 14px !important;
  }
  
  .ant-select-selection.ant-select-selection--single > div > div > div > div {
    margin-top: -20px;
  }
  
  .ant-select-selection.ant-select-selection--single[aria-expanded="true"]
    > div
    > div
    > div
    > div {
      margin-top: -10px;
  }
  
   /*style for when the menu is expanded: show shipment description above icon and name*/ 
  .ant-select-selection.ant-select-selection--single[aria-expanded="true"]
      > div
      > div
      > div
      > div
      + div {
         margin-top: -15px;
  }
  .assingproject{
    margin-left: auto!important;
    margin-right: auto!important;
  }

  .ant-row.row {
    /* border: 1px solid; */
    padding: 30px;
    /* box-shadow: 5px 10px; */
    /* box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px -6px; */
    
}
.profile{
  background-color: #cf3a3a;
}

 #dragDrop{
  position: absolute;
  left: -143px;
  width: 126%;
  height: 200px;

} 
/* button.ant-btn.login-form-button {
  margin-top: 19px;
  margin-left: 47rem;
} */
@media only screen and (max-width: 767px) {
  /* button.ant-btn.login-form-button {
    margin-left: 11rem !important;
} */

/* .card.card-statistic-1 {
  width: 20rem !important;
} */
/* .bars.d-none {
  left: 18rem;
} */
}

