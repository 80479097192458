i.fa.fa-eye {
  position: absolute;
  top: 2.7rem;
  right: 25px;
}

i.fa.fa-eye-slash {
  position: absolute;
  top: 2.7rem;
  right: 25px;
}

/* change design  23/05/23*/
.change_section {
  width: 100%;
  margin-top: 3rem;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  background: #fff5eb;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
h4.heading {
  font-weight: 600;
  color: #ff7c00;
}
.profile_section {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: left;
}

button.ant-btn.ant-btn-circle.ant-btn-primary.ant-btn-lg.ant-btn-icon-only.profile_back {
  margin-right: 1rem !important;
}