

    #FullCard{
        margin-left: 31%;
        padding-top: 1%;
    }
    #cardpunch{
 
    }
    .totaltime{
        font-size: 22px;
        margin-top: 42px;
    }
   #carddd{
 float: left;
   }
   .buttonDiv {
    text-align: right;
}
.buttonLyout{
    padding: 6px 10px !important;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* style css */
  .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
    top: -9px;
}
.punch_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
h6.heading_punch {
    font-size: 15px !important;
    font-weight: 600;
    color: #535356 !important;
}