body {
  margin: 0;
  font-family: "Poppins", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* button.btn.btn-primary.submit {
  position: absolute;
  right: 28rem;
} */


:root {
  --main-bg: #ff8f27 !important;
  --main-text: #ff8f27 !important;
  --boxshadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  --black:#111010;
  --gray:#d5d5d5;
  --main-black:#535356;
  --white:#f6f4f4;
}


body.dark-theme{
    --white:#292828;
    --black:#fafafa;
}



h1,
.h1 {
  font-size: 2rem;
}

.header-bg {
  background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
  color: #f6f4f4;
}
.bg-gray {
  background-color: #00000014;
}
.navbar-brand img {
  max-width: 90px;
  margin-right: 13px;
  filter: brightness(100) contrast(1);
}
.navbar-brand h3 {
  font-size: 21px;
}

input.input-text {
  padding: 10px;
  font-size: 12px;
  border-radius: 20px;
  color: #f6f4f4;
  min-width: 200px;
  width: 100%;
  background-color: #f6f4f4f6f4f440;
  border: 1px solid #f6f4f4f6f4f44a;
}

input.input-text::placeholder {
  color: #f6f4f4;
}

.search-img {
  max-width: 15px;
  right: 15px;
  top: 13px;
}

.user-dropdown .user-img {
  max-width: 50px;
}

.user-dropdown .dropdown-menu {
  right: 0;
  left: initial;
  top: 61px;
}

.user-dropdown .user-img {
  max-width: 50px;
  border-radius: 50%;
  margin: 0 8px;
}

.dropdown-toggle::after {
  color: #f6f4f4f;
}

.pdtb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pdlr-0 {
  padding-left: 0;
  padding-right: 0;
}

.breadcrumb {
  background-color: transparent;
  border: 0;
  padding: 0;
}
.breadcrumb a {
  color: #000;
}
.punch-det {
  padding: 7px;
  border-radius: 3px;
  border: 1px solid #00000026;
}
.punch-hours {
  height: 120px;
  width: 120px;
  display: flex;
  margin: 10px auto;
  border-radius: 50%;
  border: 5px solid #53535775;
  justify-content: center;
  align-items: center;
}
.stats-box {
  padding: 3px;
  background-color: #535357c9;
  border-radius: 4px;
  color: #f6f4f4;
  border: 1px solid #535357;
}

.statistics .row > div {
  padding: 0 5px;
}

.statistics .row {
  margin: 10px -5px;
}
.stats-info > p > strong {
  float: right;
}

.progress {
  height: 7px;
}
.stats-info > p {
  margin-bottom: 8px;
}
.stats-info {
  margin-bottom: 12px;
}

textarea#message-text {
  border: 0;
  border-radius: 0;
  padding: 10px 0px;
  border-bottom: 1px solid #ff8e27;
}

.stats-box > p {
  margin-bottom: 0px;
}

.date-filter-row .form-group {
  padding: 10px;
  border: 1px solid #00000026;
  border-radius: 3px;
  background-color: #f6f4f4;
}

.date-filter-row .form-group .form-control {
  font-size: 12px;
  border: 0;
  padding: 0;
  height: auto !important;
  margin: 0;
}

.date-filter-row .btn {
  padding: 15px;
  font-size: 18px;
}

.date-filter-row label.focus-label {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
}
.date-filter-row {
  padding: 20px 0;
}

table.attendence-table {
  width: 100%;
}
table.attendence-table tr:nth-child(odd) {
  color: #f6f4f4;
  background-color: #535357a6;
  border-bottom: 1px solid #00000026;
}
table.attendence-table th,
table.attendence-table td {
  padding: 8px;
}
.table-overflow {
  overflow: auto;
}

ul.res-activity-list {
  display: block;
  padding: 0;
  margin: 0;
}

ul.res-activity-list li {
  display: block;
  padding-left: 20px;
  position: relative;
  margin-bottom: 15px;
}
ul.res-activity-list li::before {
  content: '';
  display: block;
  height: 1px;
  width: 1px;
  position: absolute;
  left: 0;
  top: 8px;
  z-index: 1;
  border-radius: 50%;
  border: 5px solid #ff8e27;
}
p.res-activity-time {
  margin: 0;
}

ul.res-activity-list li::after {
  content: '';
  display: block;
  position: absolute;
  left: 5px;
  top: 14px;
  height: 49px;
  border-left: 1px dotted #000;
}

ul.res-activity-list li > p {
  font-size: 12px;
}
ul.res-activity-list li > p.mb-0 {
  font-weight: 600;
  font-size: 14px;
}
.punch-overflow {
  overflow: auto;
  height: 333px;
}
.mrb-15 {
  margin-bottom: -175px;
}
/* .container {
  max-width: 1280px;
} */

.modal-header {
  border-bottom: 1px solid #00000030;
}

.modal-footer {
  border-top: 1px solid #00000030;
}

.modal {
  background-color: #00000054;
}
form.login-form-100 .form-control {
  margin-bottom: 15px;
  border: 0;
  border-bottom: 1px solid #ff8e2785;
  border-radius: 4px;
}
form.login-form-100 {
  max-width: 500px;
  /* margin: 100px auto; */
  padding: 20px;
}

table.attendence-table .punch {
  min-width: 100px;
}
table.attendence-table .date {
  min-width: 110px;
}
img.logolog {
  max-width: 111px;
  display: block;
  margin: 10px auto;
}
form.login-form-100 legend {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}
form.login-form-100 .btn {
  background-color: #ff8e27;
  border-color: #ff8e27;
  margin-bottom: 10px;
}
form.login-form-100 a {
  color: #ff8e27;
}
.formplaceholder::placeholder{
  opacity: 100%;
  margin-top: 20px;
}
.btn-orange {
  background-color: #ff8e27;
  border-color: #ff8e27;
  color: #f6f4f4;
}
.brown-btn {
  background-color: #535357;
  border-color: #535257;
  color: #f6f4f4;
}
.brown-btn:hover {
  background-color: #2c2c31;
  border-color: #2c2c31;
  color: #f6f4f4;
}

@media (max-width: 767px) {
  li.user-dropdown.dropdown {
    display: block;
    width: 100%;
    text-align: center;
  }
  .navbar-brand h3 {
    display: none;
  }
  form.search-form.position-relative {
    display: none;
  }
  h1,
  .h1 {
    font-size: 1.5rem;
  }
  .date-filter-row .btn {
    padding: 8px;
    font-size: 13px;
  }
}




/* custom css */



/* deepak css */

.form-group {
  margin-bottom: 1rem !important;
}



.shadow-btn{
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}

.form-control:focus {
  color: #212529;
  background-color: #f6f4f4;
  border-color: #ff8d3e !important;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
}

.pointer{
  cursor: pointer;
}

.btn-link {
  font-weight: 400;
  color: #000 !important;
  text-decoration: inherit !important;
}

.text-xs{
  font-size: 14px !important;
}

input::placeholder {
  font-size: 14px;
  color: rgb(176, 176, 176);
}

.form-control {
  height: 40px;
}

.h-45{
  height: 45px;
}

.textprimary{
  color: #ff8f27 !important;
}


button.btn.btn-primary:hover {
  background: var(--main-bg) !important;
  border: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.all_flex{
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
}

.flex_cols{
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
  padding: 10px;
  background-color: var(--main-black);
}

.w80{
  width: 60px;
}



.ulclass {
  list-style: none;
  text-decoration: none;
  padding: 0px;
  text-align: center;
}

.ulclass li {
  padding: 5px 0px;
  cursor: pointer;
  font-size: 18px;
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  color: var(--white);
}

.ulclass li:not(:first-child):hover {
  background-color: var(--main-bg);
  border-radius: 5px;
  color: var(--white);
}


.mainwrapper_index{
  height: 100vh;
}


.inner_wraps {
  background-color: #f7f7f7;
  height: 100%;
  width: 250px;
  transition: right 0.3s;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-shrink: 0;
}

.inner_wraps_close{
  display: none;
  transition: right 0.3s;
  height: 100%;
  
}

.logo_fav {
  height: 36px;
  width: 100%;
}
.active_li{
  background-color:var(--main-bg);
  border-radius: 5px;
  color: var(--white);

}
.seting_logo {
  border: 1px solid #f6f4f4;
  background-color: #f6f4f4;
  border-radius: 5px;
  height: 30px;
  width: 30px;
}


.main_contents {
  width: 100%;
  height: 100%;
}




.hamburger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 25px;
  justify-content: space-around;
  margin: 10px;
  width: 30px;
  visibility: hidden;
}

.bar {
  width: 100%;
  height: 2px;
  background-color: var(--main-text);
  transition: all 0.3s;
}


/* Hamburger Toggle Animation */
.hamburger.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 10px);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(2px, -8px);
}

.subheads{
  font-size: 18px;
  font-weight: 600;
  color: var(--black);

}

.bordrs_btn{
  border-bottom: 1px solid #d5d5d5;
  width: 100%;
  height: 60px;
}

.sidebars_lists ul{
  padding: 0px;
  list-style: none;
}

.childins_data{
  padding: 20px;
}

.main-heading{
  font-size: 24px;
  font-weight: 600;
  color: var(--black);
}


ul.ullistsli li a {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}


ul.ullistsli li  {
margin-bottom: 4px;
}


ul.ullistsli li a:hover  {
  background-color: #fff;
  font-weight: 500;
  }

.active_side {
  color: #f6f4f4;
  background-color: #fff;
  border-right: 4px solid #ff8d3d;
  /* box-shadow: 2px 2px 5px #e5e5e5; */
  font-weight: 600 !important;
}

.childins_data{
  height: calc(100% - 80px );
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}


.main-title-new {
  color: #212529;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5px;
}


.breadcrumb-item.active {
  color: #4d5154;
}

.icons_dash {
  font-size: 20px;
  background-color: #ff8d3e;
  border: 1px solid #f69191;
  padding: 5px;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f6f4f4;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.dashtitles h4{
  font-weight: 600;
  font-size: 25px;

}

.icons_dash svg{
  font-size: 20px;
}

.postadds{
  width: 100%;
  border: 2px dashed var(--main-text);
  padding: 15px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:10px;
  cursor: pointer;
}

.postadds:hover{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}



.image-toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.image-toolbar button {
  margin-right: 10px;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.image-container input[type="text"] {
  width: 100%;
  margin-bottom: 20px;
}

.ulpad{
  padding: 0px;
  list-style: none;
  margin: 0px;
}

.texts_list{
  font-size: 14px;
  /* display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-weight: 500; */
}

.circlebreads{
  height: 25px;
  width: 25px;
  border-radius: 50px;
  background-color: var(--main-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 14px;
}



.text-primary {
  color: var(--main-text) !important;
}

.dots{
  background-color: var(--main-bg);
  padding: 4px;
  border-radius: 100px;
  height: 2px;
  width: 2px;
  display: inline-flex;
  margin-right: 4px;
}

.primary{
  color: var(--main-text);
  
}
.font800{
  font-weight: 700;
}

.smallimg{
  height: 50px;
  width: 50px;
  border-radius: 100px;
  border: 1px solid gray;
  padding: 2px;
}

.users_list h4{
  font-size: 15px;
  font-weight:600 ;

}
.users_list p{
  font-size: 13px;
  font-weight:500 ;
  line-height: 10px;
  
}

.date{
  font-size: 14px;
  font-weight: 500;
}

.time{
  font-size: 12px;
  color: #525252;
  line-height: 15px;
}

.datestime{
  text-align: end;
}

.images_data .dats_showis img{
  height: 230px;
  width: 230px;
  object-fit: contain;
}

.card_announcements .textsarea_post p {
  border-bottom: 1px solid #000;
}

.likes_comments{
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  margin-top: 10px;
  border-top: 1px solid #000;
}
.dats_showis {
  display: flex !important;
}
.textsarea_post {
  margin-left: 15px;
}


/* header dark mode css start */



.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 64px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #535356;
  transition: .4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  border-radius: 20px;
  left: 3px;
  bottom: 2px;
  z-index: 2;
  background-color: #e8e8e8;
  transition: .4s;
}

.sun svg {
  position: absolute;
  top: 6px;
  left: 36px;
  z-index: 1;
  width: 24px;
  height: 18px;
}

.moon svg {
  fill: #ff8b4d;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  width: 24px;
  height: 18px;
}

/* .switch:hover */.sun svg {
  animation: rotate 15s linear infinite;
}

@keyframes rotate {
 
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* .switch:hover */.moon svg {
  animation: tilt 5s linear infinite;
}

@keyframes tilt {
 
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-10deg);
  }

  75% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.input:checked + .slider {
  background-color: #535356;
}

.input:focus + .slider {
  box-shadow: 0 0 1px #535356;
}

.input:checked + .slider:before {
  transform: translateX(35px);
}

/* header dark mode css end */


.borderb{
  border-bottom: 1px solid var(--gray);
  padding-bottom: 6px;
  margin-bottom: 10px;
}

.pointer{
  cursor: pointer;
}


.dark-theme .card{
  background-color: #4a4a4a !important;
  color: #f6f4f4 !important;
  border: 1px solid rgb(255 255 255 / 12%) !important;
}


.dark-theme .card .card-header h4 {
  color: #f6f4f4;
}

.dark-theme .inner_wraps {
  background-color: #040303 !important;
  color: #f6f4f4;
}

.dark-theme .main_contents {
  background-color: #292828 !important;
}



.dark-theme  .active_side {
  color: #000 !important;
  background-color: #292828 !important;
  border-right: 4px solid #ff8d3d;
  box-shadow: none !important;
}

.dark-theme ul.ullistsli li a {

  color: #f6f4f4 !important;
}

.dark-theme ul.ullistsli li a:hover {
  background-color: #292828;
  box-shadow: none !important;
  border-right: 4px solid #ff8d3d;
}

.dark-theme .flex_cols {

  background-color: #292828;
}

body.dark-theme {

  color: #f6f4f4 !important;
}

.dark-theme .main-title-new {
  color: #f6f4f4;
}

.dark-theme .breadcrumb-item.active {
  color: #f6f4f4;
}

.dark-theme .modal-content  .modal-title{
  color: #292828;
}

.dark-theme .cstmlabel{
 color: var(--black);
}

.dark-theme .ulclass li {
  color: #fff !important;
}

.dark-theme .MuiPaper-root {
  color: #fff !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #383838 !important;
}



.dark-theme .laels_left p,.dark-theme .rights_texts p{
  color: #fff !important;
}



.design{
  font-size: 18px;
  font-weight: 600;
  color: var(--black);

}

.roles{
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
  line-height: 14px;
  
}

.datejoin{
  font-size: 14px;
  font-weight: 500;
  color: var(--black);
}

.datejoin span{
  font-size: 14px;
  font-weight: 500;
  color: var(--black);
}





.headings{
  background-color: var(--main-bg);
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.heading-main{
  font-size: 20px;
  color: var(--white);
}


.ck.ck-editor__editable_inline {
  border: 1px solid transparent;
  overflow: auto;
  padding: 0 var(--ck-spacing-standard);
  height: 200px;
  width: 100%;
  line-break: anywhere;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border:1px solid  var(--main-bg) !important;
  box-shadow: var(--main-bg),0 0 !important;
  outline: none;
}
.ck-powered-by-balloon{
  display: none !important;
}

.profile_img{
  width: auto;
  height: 150px;
}

.edirprofile
.form-group label {
    text-align: left !important;
    display: flex;
}

.radius_div{
  padding: 10px;
  background-color: #e9e9e9;
  border-radius: 10px;
}

.dark-theme .radius_div{
  color: #000 !important;
}

.imgknowledge{
  width: auto;
  height: 150px;
}

.wrapperauth{
  background: url("../public/img/login-bg.jpg") !important;
  background-position: center;
  background-size: cover;
  
}


/* HTML: <div class="loader"></div> */
.loader {
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: l20-1 0.8s infinite linear alternate,
  l20-2 1.6s infinite linear;
  margin: 0 auto;
}
@keyframes l20-1{
   0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
   12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
   25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
   50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
   100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{ 
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}

.lbreak{
  line-break: anywhere;
}

.sidebars_lists {
  overflow-y: auto;
  height: calc(100vh - 100px);
  padding-right: 10px;
}

/* Custom Scrollbar Styles */
.sidebars_lists::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.sidebars_lists::-webkit-scrollbar-thumb {
  background-color: #e1e1e1; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.sidebars_lists::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
}
.w-60{
  width: 300px;
}
.marquee_div {
  font-size: 18px;
  color: #e36610;
}
.marquee {
  background: #f9f9f9;
  padding: 6px 0px;
}

.anouments {
  width: 100%;
  object-fit: contain;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}




.autoscroll {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(90vh - 100px);
  scrollbar-width: none; /* For Firefox */
}

.autoscroll::-webkit-scrollbar {
  display: none; /* For WebKit browsers (Chrome, Safari) */
}
