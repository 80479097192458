.mainheading{
    text-align: center;
}
.mainclass{
    text-align: center
}
.form{
    text-align: center;
}

   
#logouticon{
    float: right;
}
.inputform{
    width: 62%;
    float: right;
}
#slectgender{
    width: 62%;
    float: right;
}
.formrow{
    text-align: -webkit-center;
}

i.fas.fa-edit.view.icon {
    position: absolute;
    left: 52.5%;
    background: #f7a01d;
    padding: 10px;
    border-radius: 50px;
    top: 29.5%;
    color: #fff;
}
#sec{
    float: left;
}
#SecondaryAddress1{
      margin-top: -30px
}
  .gendee{
    padding-left: 158px;
  }

#slectgender{
    margin-top: -8px;
}
 .editbtn{
    display: flex;
    justify-content: center;
 }

 .uploadimg{
     color: blue;
 }

 /* .img{
    vertical-align: middle;
    width: 90px;
    height: 90px;
    border-radius: 50%;
 }
   */
 .uploadbtn {
    position: absolute;
    left: 687px;
    top: 102px;
    width: 16px;
    cursor: pointer;
    opacity: 0;
    z-index: 9999;
}

 
/* 
 .uploadbtn{
    position: absolute;
    left: 30rem;
    top: 13.5rem;
    cursor: pointer;
    opacity: 0;
    z-index: 9999;
    
 } */

.submitbtn{
    position: absolute;
    top: 16rem;
    left: 31rem;
}



i.fa.fa-times.crossi {
    position: absolute;
    top: 106px;
}
.mainupload {
    position: relative;
  }
  .proImg{
      display: inline-block;
      position: relative;
  }
  @media (max-width: 767px){
.section .section-header h1 {
    font-size: 14px;
}

.main-content{
    min-height: 0px !important;
}
  }

  /* style css */
  label {
    font-size: 14px;
    font-weight: 600;
}



/* new css profiles */

.names{
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 3px !important;
}

.details_pros{
    border-left: 2px dashed #a4a4a4;
    padding: 20px;
    height: 100%;
}

.laels_left{
    width: 30%;
}

.laels_left p{
    font-size: 16px;
    color: #0f0f0f;
    font-weight: 600 !important;
}

.rights_texts{
    width: 70%;
}

.rights_texts p{
    font-weight: 500 !important;
    color: rgb(59, 57, 57);
}

button.btn.btn-primary.profile {
    position: absolute;
    top: 8px;
    right: 16px;
    color:#fff;
    background-color: #f9902c !important;
    border-color: #fff !important;
    box-shadow: 0 0px 0px !important;
  }

  .sub-heads{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px !important;
    display: block;
  }
  .mainupload {
    border: 2px dashed #d5c8ca;
    width: 250px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    padding: 20px;
}

.editicons {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
    background: #f7a01d;
    padding: 2px;
    width: 29px;
    height: 29px;
    border-radius: 50px;
    color: #fff;
}
