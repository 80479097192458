#headermain{
    background-color: #ff8e27;
}
/* .maindashdiv{
    background-image: url("../../../src/assets/img/dashbord_img.png");
    width: 100%;
   
    background-size: cover;
    background-position: top;

} */



.chatcls {
    width: 395px;
    padding: 27px 13px;
    height: auto;
    background: #fff;
    box-shadow: 0px 0px 5px 0px #808080bd;
    border-radius: 17px;
}
.chatcls.mb-3 {
    margin-left: 4rem;
}

img.nodataimg {
    opacity: 0.6;
    width: 160px;
    height: 160px;
}


