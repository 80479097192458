button.ant-btn.ant-btn-primary.form-control.submitbtn1 {
    max-width: 153px;
}
.submit_btncls{
    text-align: center;
}

/* .submit{
   
    float: right;
} */
.Timer{
    position: relative;
    top: 22px;
    left: -128px;
    position: absolute;
    top: 12px;
    left: 49rem;
   
}

h3.login_heading {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;
    color: #f98f2d;
    margin-top: 1rem;
}
.jc_logins {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #ffe4ce;
    
}

.jc_login {
    display: flex;
    /* width: 23rem; */
    justify-content: center;
    align-items: center;
    /* height: 100%; */
    background-color: #ffe4ce;
    /* background-image: url('../assets/img/G6dobw.jpg'); */
    /* background-image: url('../assets/img/Login.png'); */
    /* background-image: url('../assets/img/recherche_internet_fuites_informations-rifi.jpeg'); */
    background-size: cover;
}
.ant-btn-primary {
    color: #fff;
    border-color: #ff9134;
    background: #ff9134;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.star{
    color: red;
}
.col-md-9.mx-auto {
    height: 100vh;
    align-items: center;
    display: flex;
}
h1.Nametext_class {
    color: #ffff;
    font-size: 24px;
}

img.login_logo {
    width:8.8rem;
    display: block;
    margin: auto auto 2rem;
    object-fit: contain;
    position: relative;
    top: 3rem;
}
.ant-input-prefix {
    position: absolute;
    left: 22rem;
    top: 0.5rem;
    z-index: 9999;
}
.login_jc {
    padding: 0px;
}

/* .submitbtn1{
    position: relative;
    left: 24.5rem;
} */
/* button.ant-btn.ant-btn-primary.submitbtn1 {
    position: absolute;
    left: 12.5rem;;
    
} */
/* 
@media (max-width: 767px) {
    button.ant-btn.ant-btn-primary.submitbtn1 {
        position: absolute;
        left: 16rem !important;
    }
    }

@media (max-width: 768px) {
    button.ant-btn.ant-btn-primary.submitbtn1 {
        position: absolute;
        left: 24rem;
    }
    }
    @media (max-width: 280px){
    button.ant-btn.ant-btn-primary.submitbtn1 {
        position: absolute;
        left: 10rem !important;
    }
}
@media (max-width: 320px){
button.ant-btn.ant-btn-primary.submitbtn1 {
    position: absolute;
    left: 13rem !important;
}
}

@media (max-width: 767px){
button.ant-btn.ant-btn-primary.submitbtn1 {
    position: absolute;
    left: 24rem !important;
}
} */



/* login new css */
.buttons {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.ant-form-item-explain-error {
    color: #ff4d4f;
    margin-top: 10px;
    margin-left: 4px;
    margin-bottom: 10px;
}
input#basic_email {
    border: none;
    border-bottom: 1px solid #d9d9d9;
    margin-top: 12px;
}
span.ant-input-affix-wrapper.ant-input-password {
    border: none;
    margin-top: 12px;
    border-bottom: 1px solid #d9d9d9 !important;
    background-color: #e9f0fe;
}
lable.changes {
    font-size: 14px;
    font-weight: 600;
}
img.login_img {
    width: 100%;
    height: auto;
    max-width: 350px;
}
input.ant-input {
    background-color: transparent;
}


/* new css by deepak */

.wrapperauth {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    height: 100vh;
    z-index: 8;
    background: var(--body-color);
}

.text-md{
    font-size: 20px;
    font-weight: 500;
}

.text-xl{
    font-size: 26px;
    font-weight: 600;
}

.forms_data{
    padding: 30px;
    border: 1px solid #767575;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    background-color: #ffffff;
    width: 60%;
    margin: 0 auto;
}

.password_eye{
    position: absolute;
    right: 10px;
    top:50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  