@media (max-width: 767px) {
    button.ant-btn.ant-btn-primary.edit_btn.edit_btn.mb-2 {
        margin-left: 21px !important;
    }
}
span.anticon.anticon-edit {
    background: #f9902c;
    padding: 9px;
    border-radius: 50%;
    color: #fff;
}
.action_section {
    display: flex;
    align-items: baseline;
}
.delete_btn.mt-2 {
    width: 30px inherit;
    width: 32px;
    height: 32px;
    background: rgb(255, 114, 114);
    color: #fff;
    border-radius: 50%;
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}