/* .jc_login {
    display: flex;
    width: 23rem;
    justify-content: center;
    align-items: center;
    background-color: rgb(91 90 93 / 55%);
    background-image: url('../assets/img/G6dobw.jpg');
    background-size: cover;
} */
.forget_page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

/* .login_jc{
    padding: 61px;
} */

.titel_class{
    color: #ffff;
}
.forgot_jc {
    height: 100%;
    padding: 30px;
}
label {
    font-size: 16px;
    font-weight: 600;
}
.logincss_class{
    color: #ff8e27;
}
.forgotcss{
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    line-height: inherit;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}
.submitcss{
    background: #ff8e27;
    color: #ffff !important;
    font-size: 14px;
}

@media (max-width: 1024px) {
    /* button.btn.btn-primary.submit {
        position: absolute;
        right: 18rem;
    } */
    }

    @media (max-width: 768px) {
        button.btn.btn-primary.submit {
            position: absolute;
            right: 9rem;
        }
        }
        @media (max-width: 767px) {
            button.btn.btn-primary.submit {
                position: absolute;
                right: 1rem;
            }
           
           
            }

            @media (max-width: 990px) {
            .forgot_jc {
                padding: 30px;
                height: 100% !important;
                width: 100% !important;
            }


           /* .jc_login.p-0 {
                display: none !important;
            } */

            
        }